import React, { Component } from 'react'
import MobileBanner from 'react-mobile-banner';

import Icon from '../../assets/img/v2/app-store.png'
import ocode from '../../assets/img/v2/o-code-big.svg'
import ocodeLogo from '../../assets/img/v2/logo-ocode-big-full.svg'
import target from '../../assets/img/v2/cible-scan.svg'
import phonebackground from '../../assets/img/v2/Phone.svg'
import { sharedAPI } from '../../assets/lib/api'
import moment from 'moment';

const styles ={
  video: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  canvas: {
    opacity: 0,
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: "100%",
    height: "100%"
  }
} 

var canvas, video, ctx, track
var constraints = { video: { facingMode: 'environment' }, audio: false }

function delay(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

export default class ScanOcode extends Component {

  constructor (props) {
    super(props)

    let bannerView = localStorage.getItem("banner-view")
    let showBanner = true

    if (bannerView && moment().isBefore(moment(bannerView))) showBanner = false

    this.state = {
      cameraEnable: false,
      bannerShow: showBanner,
      scanningOcode: false,
      unavailableOCode: false,
      unavailableOCodeWindow: {
        title: 'Objet protégé',
        text: '',
        operator: null,
        login_url: null
      }
    } 

    this.backend = sharedAPI()
    this.timer = null

    this.closeBanner = this.closeBanner.bind(this)
    this.enableCamera = this.enableCameraHandler.bind(this)
    this.cameraStart = this.cameraStart.bind(this)
    this.cameraDraw = this.cameraDraw.bind(this)
    this.onPlay = this.onPlay.bind(this)
    this.process = this.process.bind(this)
  }

  closeBanner() {
    localStorage.setItem("banner-view", moment().add(15, "days").format())
  }

  enableCameraHandler () {
    this.setState({ ...this.state, cameraEnable: true }, () => {
      window.addEventListener('ocode', this.process,false)
      this.cameraStart()
    })
  }

  cameraDraw () {
    if (!video || video.paused || video.ended) return
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
    const image = ctx.getImageData(0, canvas.height * .25, canvas.width, canvas.height / 2)
    const buffer = new ArrayBuffer(image.data.length)
    const uint8 = new Uint8Array(buffer)
    uint8.set(image.data)
    try {
      window.detectOcode(uint8, image.width, image.height)
    } catch (err) {
      console.log ('>', err)
    }
    this.timer = setTimeout(this.cameraDraw, 0)
  }

  onPlay () {
    const ratio = video.videoWidth / video.videoHeight
    const w = 1200
    const h = parseInt(w / ratio)
    canvas.style.width = `${w}px`
    canvas.style.height = `${h}px`
    canvas.width = w
    canvas.height = h

    this.cameraDraw()
  }

  async cameraStart () {
    video = document.getElementById('live')
    video.setAttribute('autoplay', '');
    video.setAttribute('muted', '');
    video.setAttribute('playsinline', '');
    canvas = document.getElementById('canvas')
    ctx = canvas.getContext('2d')
    window.ctx = ctx
    // if (!navigator.mediaDevices) {
    //   alert('navigator.mediaDevices is not supported')
    //   return
    // }
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      track = stream.getTracks()[0]
      video.srcObject = stream
      window.localstream = stream
    } catch (err) {
      alert(err)
      console.error('Oops. Something is broken', err)
    }
  }

  async process (e) {
    if (e.detail !== '0') {
      this.setState({ scanningOcode: true })
      // console.table({ ocode: e.detail })
      if (this.timer) clearTimeout(this.timer)
      this.timer = null
      window.removeEventListener('ocode', this.process, false)
      // Récupérer le ocode id
      const ocode_id = e.detail
      // Récupérer un identifiant et se connecter au chat
      const url = await this.backend.connectToChat(ocode_id)
      this.setState({ scanningOcode: false })
      if (typeof url === 'string' && url === "No Owner") {
        alert("Vous ne pouvez pas contacter le propriétaire car le O°code n’est pas activé.")
        window.addEventListener('ocode', this.process,false)
      } else if (typeof url === 'object') {

        // Là on est dans le cas d'une erreur
        if(url.error) {
          alert(url.error.userMessage)
          window.addEventListener('ocode', this.process,false)
          return
        }

        let { login_url, operator } = url

        this.setState(prevState => {
          return {
            ...prevState,
            cameraEnable: false,
            unavailableOCode: true,
            unavailableOCodeWindow: {
              title: 'Objet protégé',
              text: operator === 'WO' ? "Pour plus d'informations, rejoignez votre Wallet" : "Pour plus d'informations, rejoignez votre espace Bicycode",
              login_url,
              operator,
            }
          }
        })

        window.addEventListener('ocode', this.process,false)

      } else {
        // Aller sur la page du chat
        this.props.history.push(url)
      }
    }
  }

  onCloseModal (e) {
    if (e.target === e.currentTarget) {

      this.setState(prevState => {
        return {
          ...prevState,
          unavailableOCode: false,
          unavailableOCodeWindow: {
            title: 'Objet protégé',
            text: null,
            login_url: null,
            operator: null,
          }
        }
      })

    }
  }

  componentWillUnmount () {
    window.localstream.getTracks().forEach(function(track) {
      if (track.readyState == 'live' && track.kind === 'video') {
        track.stop();
      }
   })
  }

  render() {
    return (
      <div className='scan-container'>
        <MobileBanner 
            showBanner={this.state.bannerShow} 
            position="top"
            topStickyBanner={false}
            appTitle='O°Code'
            appDescription='Rendons leurs objets à leurs propriétaires'
            appId={{android:'com.safethingmobile',ios:'id1348670856'}}
            starRatingCount={4.5}
            backgroundColor=""
            appIcon={"https://is4-ssl.mzstatic.com/image/thumb/Purple114/v4/59/d5/b6/59d5b63b-0f1b-222c-9c3d-872a2ef99815/AppIcon-1x_U007emarketing-0-5-85-220.png/492x0w.webp"}
            onClose={()=>{
              this.closeBanner()
              this.setState({bannerShow:false})
            }}
            onInstall={this.closeBanner}
        />
        {!this.state.cameraEnable && <div className='scan-wrapper'>
          <img className='phone-background' src={phonebackground} />
          
          {this.state.unavailableOCode && <div className='unavailableOCodeWindow' onClick={this.onCloseModal.bind(this)}>
            <div className='unavailableOCodeModel'>
              <h2 style={{ marginBottom: 10 }}>{this.state.unavailableOCodeWindow.title}</h2>
              <p style={{ marginBottom: 16 }}>{this.state.unavailableOCodeWindow.text}</p>
              <p style={{ textAlign: 'center' }}>
                <a href={this.state.unavailableOCodeWindow.login_url} target='_blank' className='unavailableOCodeButton'>Se connecter</a>
              </p>
            </div>
          </div>}

          <img id='scan-ocode-logo' style={{ marginTop: 30, marginBottom: 40 }} src={ocodeLogo} />
          <div style={{ textAlign: 'center' }}>
            <button className='scan-button' onClick={this.enableCamera}>
              <img src={ocode} />
            </button>
            <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
              <svg id='scan-arrow' width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.53714 20.0827L0.292152 10.9458C-0.097384 10.5612 -0.097384 9.93981 0.292152 9.5542L9.53714 0.417333C10.0995 -0.139111 11.0144 -0.139111 11.5777 0.417333C12.14 0.973776 12.14 1.87687 11.5777 2.43332L3.66913 10.2505L11.5777 18.0657C12.14 18.6231 12.14 19.5262 11.5777 20.0827C11.0144 20.6391 10.0995 20.6391 9.53714 20.0827Z" fill="#8E8E8E"/>
              </svg>
              <p id='scan-text-ocode'>
                <span className='text-big'>Scannez</span><br />
                pour contacter<br /> anonymement le propriétaire et lui restituer son bien
              </p>
            </div>
          </div>
        </div>}
        {this.state.cameraEnable && <>
          <div className='scan-wrapper' style={{ padding: 0 }}>
            <img className='phone-background' src={phonebackground} />
            {this.state.scanningOcode && <div id="searching-ocode">
              <span className="loader"></span>
            </div>}
            <p id='scan-ocode-title'>Scannez un O°Code</p>
            <video id="live" playsInline autoPlay onPlay={this.onPlay} style={styles.video}></video>
            <canvas id="canvas" style={styles.canvas}></canvas>
            <div id='scan-target'>
              <img src={target} />
              <p>Placez le O°Code dans le cadre</p>
            </div>
          </div>
        </>}
      </div>
    )
  }
}
