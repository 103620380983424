import React from "react";
import classnames from "classnames";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Dropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";
import i18n from "../../assets/lib/i18n";
import {StrDef} from '../../assets/lib/utils';
import AllocBar from '../AllocBar/AllocBar';

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent"
    };

    this.Logout = this.Logout.bind(this);
    this.Settings = this.Settings.bind(this);
    this.Account = this.Account.bind(this);

    this.gender = sessionStorage.getItem('gender');

    this.entityUser = sessionStorage.getItem('entity');
    if( StrDef(this.entityUser) ) this.entityUser = JSON.parse(this.entityUser);
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
        collapseOpen: true,
      });
    } else {
      this.setState({
        color: "navbar-transparent",
        collapseOpen: false,
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  Logout(e) {
    if( StrDef(e) && StrDef(e.preventDefault) ) e.preventDefault();
    sessionStorage.clear();
    this.props.history.push('/home');
  }

  Settings(e) {
    if( StrDef(e) && StrDef(e.preventDefault) ) e.preventDefault();
    this.props.history.push('/'+this.gender+'/settings');
  }

  Account(e) {
    if( StrDef(e) && StrDef(e.preventDefault) ) e.preventDefault();

    var user = sessionStorage.getItem('user');
    if( StrDef(user) ) user = JSON.parse(user);

    this.props.history.push('/'+this.gender+'/account/'+user.userId);
  }

  render() {
    var username = 'Jean Laurent Vacheron';
    if( StrDef(this.entityUser) && StrDef(this.entityUser.company) ) username = this.entityUser.company;

    return (
      <>
        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              { false &&
              <div className="navbar-minimize">
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              }
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              { this.gender=='agent' &&
                <>
                  <span className="d-none d-md-block"><AllocBar/></span>
                  <span className="d-block d-md-none"><AllocBar/></span>
                </>
              }
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>



            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar>
                { (this.state.collapseOpen && this.gender=='grower') &&
                  <>
                    <Button className="btn-link" color="default" onClick={this.Settings}>{i18n.t('navbar.'+this.gender+'Settings')}</Button>
                    <Button className="btn-link" color="default" onClick={this.Logout}>{i18n.t('app.logout')}</Button>
                  </>
                }
                { (this.state.collapseOpen && this.gender=='agent') &&
                  <>
                    <Button className="btn-link" color="default" onClick={this.Account}>{i18n.t('navbar.'+this.gender+'Account')}</Button>
                    <Button className="btn-link" color="default" onClick={this.Logout}>{i18n.t('app.logout')}</Button>
                  </>
                }
                { !this.state.collapseOpen &&
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <span className="text-muted" style={{textTransform:'none',fontSize:'10px',marginRight:'10px'}}>Connecté en tant que</span>
                    <span className="text-muted">{username}</span>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                    { this.gender=='grower' &&
                      <>
                        <DropdownItem onClick={this.Settings}>{i18n.t('navbar.'+this.gender+'Settings')}</DropdownItem>
                        <DropdownItem onClick={this.Logout}>{i18n.t('app.logout')}</DropdownItem>
                      </>
                    }
                    { this.gender=='agent' &&
                      <>
                        <DropdownItem onClick={this.Account}>{i18n.t('navbar.'+this.gender+'Account')}</DropdownItem>
                        <DropdownItem onClick={this.Logout}>{i18n.t('app.logout')}</DropdownItem>
                      </>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
                }
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
