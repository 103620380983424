import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import i18n from "../../assets/lib/i18n";
import {sharedAPI} from "../../assets/lib/api";
import {StrDef,Phone,GetJSON,SortBy} from '../../assets/lib/utils';

import cepage from '../../assets/data/cepage.json';

class Agents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list:[],   // wines

    };

    this.backend = sharedAPI();

    this.AddWine = this.AddWine.bind(this);
    this.EventRefresh = this.EventRefresh.bind(this);

    this.LoadList();
  }

  componentDidMount() {
    document.removeEventListener('refreshWineList', this.EventRefresh, false);
    document.addEventListener('refreshWineList', this.EventRefresh);
  }

  componentWillUnmount() {
    document.removeEventListener('refreshWineList', this.EventRefresh, false);
  }

  EventRefresh() {
    this.LoadList();
  }

  LoadList() {
    this.backend.kv_group_get({group:'WINE'},(res)=>{
      if( StrDef(res.error) ) {

      }
      else {
        var tmp = [];
        for(var i=0;i<res.length;i++) {
          tmp.push(res[i].parameter_value);
        }
        this.setState({list:SortBy(tmp,'name')});
      }
    });
  }

  Edit(data) {
    this.props.history.push( {pathname:'/grower/agent/'+data.entity_id,
                              state: {zoneUsed:this.state.zoneUsed}} );
  }

  RenderList(status) {
    if( this.state['list'+status].length == 0 ) {
      return (
        <Card>
          <CardBody>
            <p>Vous n'avez pas encore validé d'agent, bouh !!!, quel dommage, les bouteilles ne vont pas se vendre toutes seules !!!!!!!</p>
          </CardBody>
        </Card>
      );
    }
    return(
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">{status==0?i18n.t('wines.waiting'):i18n.t('agents.open')}</CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive className="table-hover">
                <thead className="text-primary">
                  <tr>
                    <th style={{minWidth:'200px'}}>Nom</th>
                    <th>Allocation</th>
                    <th>Téléphone</th>
                    <th>Email</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  { this.state['list'+status].map((data,i)=>{
                      var name = data.long_description.company;
                      var phone = data.long_description.phone;
                      var mail = data.long_description.mail;
                      var alloc = data.long_description.allocation;
                      return (
                        <tr key={'tr'+i}>
                          <td>{name}</td>
                          <td>{alloc}</td>
                          <td>{Phone(phone)}</td>
                          <td>{mail}</td>
                          <td className="text-right">
                            <Button className="btn-icon" color="primary" id={'action1'+i} size="sm" type="button" onClick={this.Edit.bind(this,data)}>
                              <i className="fa fa-edit" />
                            </Button>{" "}
                            <UncontrolledTooltip delay={0} target={'action1'+i}>Editer l'agent</UncontrolledTooltip>
                          </td>
                        </tr>);
                    })
                  }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  AddWine() {
    this.props.history.push('/grower/wine/add')
  }

  EditWine(data) {
    this.props.history.push('/grower/wine/'+data.prod_id);
  }

  render() {
    return (
      <>
        <div className="content">
          <h3>{i18n.t('wines.title')}</h3>
          <Button color="primary" onClick={this.AddWine}>{i18n.t('wines.add')}</Button>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Table responsive className="table-hover">
                    <thead className="text-primary">
                      <tr>
                        <th style={{minWidth:'200px'}}>Nom</th>
                        <th className="text-right"></th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.state.list.map((data,i)=>{
                          var name = data.name;
                          return (
                            <tr key={'tr'+i}>
                              <td>{name}</td>
                              <td className="text-right">
                                <Button className="btn-icon" color="primary" id={'action1'+i} size="sm" type="button" onClick={this.EditWine.bind(this,data)}>
                                  <i className="fa fa-edit" />
                                </Button>{" "}
                                <UncontrolledTooltip delay={0} target={'action1'+i}>{i18n.t('wines.edit')}</UncontrolledTooltip>
                              </td>
                            </tr>);
                        })
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Agents;
