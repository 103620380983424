import React, { Component } from 'react';
import Linkify from 'react-linkify';
import i18n from "../../../../assets/lib/i18n";
import {StrDef} from "../../../../assets/lib/utils";
import moment from 'moment';
import _ from 'lodash';
const isMobile = require('ismobilejs');

const TextMessage = (props) => {

  var ts = moment.utc(props.data.ts).local();
  if( ts.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') ) {
    ts = ts.format('HH:mm');
  }
  else {
    ts = ts.format('DD/MM HH:mm');
  }

  var onclick = null;
  // if( StrDef(props.data.geo) && props.data.geo === true ) {
  //   if (props.data.text) {
  //     props.data.onclick = () => {
  //       const url = props.data.text
  //       console.log("URL", url)
  //       window.open(url, '_blank')
  //       // const device = isMobile
  //       // if (device.android.device || device.apple.phone || device.apple.tablet) {
  //       //   // Ouvrir l'application de géolacalisation par défaut du téléphone
  //       //   const params = new URLSearchParams('?' + url.split('?')[1])
  //       //   const [ latitude, longitude ] = params.get('query').split(',')
  //       //   window.location = `geo:${latitude},${longitude}`
  //       // } else {
  //       //   // Ouvrir une autre fenêtre web vers google map
  //       //   window.open(url, '_blank')
  //       // }
  //     }
  //   }
  // }
  if( StrDef(props.data.onclick) ) {
    if( props.data.geo === true ) {
      if (props.data.text) {
        onclick = props.data.onclick
      } else {
        onclick = props.data.parent.onGeoClick.bind(props.data.parent,props.data.onclick);
      }
    }
    if( StrDef(props.data.photo) ) onclick = props.data.parent.onPhotoClick.bind(props.data.parent,props.data.onclick);
  }

  return <div className="sc-message--text" style={{ cursor:StrDef(props.data.onclick) ? 'pointer':'default' }} onClick={onclick}>{
    <Linkify properties={{ target: '_blank' }}>
      <div>
        <div className={'msgText'+props.data.user} style={StrDef(props.data.photo) ? {padding:0} : StrDef(props.data.geo) ? {background:'#26CFAD'} : null}>
          { props.data.geo === true &&
            <span style={{marginLeft:'20px', color: '#FFFFFF'}}>{i18n.t('chatWindow.geoloc')}</span>
          }
          { props.data.geo !== true &&
            <span>{props.data.text}</span>
          }
          { StrDef(props.data.photo) &&
            <img src={props.data.photo} style={{objectFit:'cover',width:'200px',height:'200px',borderRadius:'10px',borderWidth:'3px',borderColor:'rgba(235,131,29,1)',borderStyle:'solid'}}/>
          }
        </div>
        <div style={{position:'relative',color:'grey',textAlign:props.data.user=='Owner'?'right':'left',fontSize:'0.75em',marginBottom:'5px'}}>
          {ts}
        </div>
        { props.data.geo === true &&
          <div style={{position:'relative',width:'33px',height:'33px',background:'transparent',borderRadius:'16px',float:'left',marginTop:'-65px',marginLeft:'10px',justifyContent:'center',alignItems:'center'}}>
            <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9 22.7368C8.76603 22.7368 8.53791 22.663 8.34751 22.5256C8.00551 22.2811 -0.0325256 16.4161 9.90413e-05 9.08912C9.90413e-05 4.08016 4.03768 0 9 0C13.9623 0 17.9999 4.08016 17.9999 9.09481C18.0325 16.4161 9.99449 22.2811 9.65249 22.5256C9.46209 22.663 9.23397 22.7368 9 22.7368ZM15.7499 9.10049C15.7499 5.33297 12.7226 2.2737 9 2.2737C5.27742 2.2737 2.25007 5.33297 2.25007 9.09481C2.22645 14.1436 7.18764 18.6751 9 20.1621C10.8135 18.6762 15.7736 14.1458 15.7499 9.10049ZM13.2632 9C13.2632 11.3511 11.3511 13.2632 9 13.2632C6.64887 13.2632 4.73684 11.3511 4.73684 9C4.73684 6.64887 6.64887 4.73684 9 4.73684C11.3511 4.73684 13.2632 6.64887 13.2632 9ZM11.1316 9C11.1316 7.82443 10.1756 6.86842 9 6.86842C7.82443 6.86842 6.86842 7.82443 6.86842 9C6.86842 10.1756 7.82443 11.1316 9 11.1316C10.1756 11.1316 11.1316 10.1756 11.1316 9Z" fill="white"/>
            </svg>
          </div>
        }
      </div>

    </Linkify>
  }</div>
}

export default TextMessage
