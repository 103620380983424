import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MessageList from './MessageList'
import UserInput from './UserInput'
import Header from './Header'

import phoneBackground from '../../../assets/img/v2/Phone.svg'


class ChatWindow extends Component {
    constructor(props) {
      super(props);
      this.state = {
        waitingForResponse:false,
      };
    }

    componentWillReceiveProps(nextProps) {
      if( typeof(nextProps.waitingForResponse) != 'undefined' ) this.setState({waitingForResponse:nextProps.waitingForResponse});
    }

    onUserInputSubmit(message) {
      this.props.onUserInputSubmit(message);
    }

    onShareGeolocate(position) {
      this.props.onShareGeolocate(position);
    }

    onFilesSelected(filesList) {
      this.props.onFilesSelected(filesList);
    }

    render() {
      let messageList = this.props.messageList || [];
      let classList = [
        "sc-chat-window",
        (this.props.isOpen ? "opened" : "closed")
      ];
      return (
        <div className={classList.join(' ')}>
          <img className='phone-background' src={phoneBackground} />
          <Header
            teamName={this.props.agentProfile.teamName}
            imageUrl={this.props.agentProfile.imageUrl}
            onClose={this.props.onClose}
          />
          <MessageList
            messages={messageList}
            imageUrl={this.props.agentProfile.imageUrl}
          />
          {/* { !this.state.waitingForResponse && */}
            <UserInput
              placeholder={this.props.placeholder}
              onSubmit={this.onUserInputSubmit.bind(this)}
              onShareGeolocate={this.onShareGeolocate.bind(this)}
              onFilesSelected={this.onFilesSelected.bind(this)}
              showEmoji={this.props.showEmoji}
            />
          {/* } */}
          { this.state.waitingForResponse &&
            <div id='waiting-text'>
              {this.props.waitingText}
            </div>
          }
        </div>
      );
    }
}

ChatWindow.propTypes = {
  agentProfile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func,
  onShareGeolocate: PropTypes.func,
  onUserInputSubmit: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool
}

export default ChatWindow;
