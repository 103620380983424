import React from "react";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import Select from "react-select";
import Slider from "nouislider";
import {StrDef,Region} from '../../assets/lib/utils';
import {Map,
        TileLayer,
        Marker,
        Tooltip,
        Circle,
        CircleMarker,
        GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import chroma from 'chroma-js';


// GeoData : departement france metropolitaine
import GeoData from './dept.json';

const COLOR_SELECTED = '#7057D2';
const COLOR_NORMAL = '#333';

var TILE_PROVIDER_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
var TILE_ATTRIBUTION = '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'

// LIGHT
TILE_PROVIDER_URL = "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png";
TILE_ATTRIBUTION = "Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL.";

//GRAYSCALE
//TILE_PROVIDER_URL = "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png";

// OSM HOT
//TILE_PROVIDER_URL = "http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png";

// wikimedia
//TILE_PROVIDER_URL = "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png";

// OSM FRENCH
//TILE_PROVIDER_URL = "http://a.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png";


class OMap extends React.Component {
  constructor(props) {
    super(props);

    this.OnClick = this.OnClick.bind(this);

    this.GeoStyle = this.GeoStyle.bind(this);
    this.GeoEachFeature = this.GeoEachFeature.bind(this);
    this.GeoFilter = this.GeoFilter.bind(this);
    this.MarkerClick = this.MarkerClick.bind(this);
    this.OnZoom = this.OnZoom.bind(this);

    this.state = {
      lat: this.props.lat || 46.668851958993706,
      lng: this.props.lng || -1.4336319766635368,
      zoom: 6,
      height:'calc(100vh - 150px)',
      width:'calc(100vw - 60px)',
      points:[],
      selection:[],
      displayDept:true,
      displayDeptLabel:true,
      used:[],
      dragging:true,
      mode:this.props.mode,
      zones:this.props.zones,
    };

    if( StrDef(this.props.disabled) && this.props.disabled ) {
      this.state.dragging = false;
      this.state.disabled = true;
    }

    if( StrDef(this.props.used) ) {
      this.state.used = this.props.used;
    }

    if( StrDef(this.props.selection) ) {
      var tab = [];
      for(var i=0;i<this.props.selection.length;i++) {
        tab.push( this.props.selection[i].code );
      }
      this.state.selection = tab;
    }

    if( this.props.mode == 'allZone' ) {
      this.state.displayDeptLabel = false;
    }

    this.tabCodeName = {};
    this.points = [];
    this.allCoord = [];   // contient les coord des dept selectionnes - sert pour centrer la carte au debut
  }

  componentDidMount() {

  }

  componentDidUpdate() {
    //console.log( 'componentDidUpdate !!!!' );
  }

  OnClick(e) {
  }

  MarkerClick(d) {
    if( this.state.disabled ) return;
    this.DeptClick(d);
  }

  GeoStyle(d) {
    var style = {
      color: COLOR_NORMAL,
      weight: 1,
      opacity: 0.9
    };

    if( this.state.selection.indexOf(String(d.properties.code)) != -1 ) {
      style.color = COLOR_SELECTED;
      style.fillOpacity = 0.2;
      if( this.state.mode == 'allZone' ) {
        style.fillOpacity = 1;
        var scale = chroma.scale(['#7057D2', '#4B244A']);
        var zone = this.state.zones;
        for(var i=0;i<zone.length;i++) {
          for(var j=0;j<zone[i].zone.length;j++) {
            if( zone[i].zone[j].code == d.properties.code ) {
              style.color = scale(i/zone.length).hex();
            }
          }
        }
      }
    }
    else {
      style.color = COLOR_NORMAL;
      style.fillOpacity = 0;
    }

    return style;
  }

  GeoEachFeature(feature,layer) {
    this.tabCodeName[feature.properties.code] = feature.properties.nom;

    var coord = feature.geometry.coordinates[0];
    if( feature.geometry.coordinates[0][0].length > 2 ) coord = feature.geometry.coordinates[0][0];
    if( feature.properties.code == "35" ) coord = feature.geometry.coordinates[1][0];
    if( feature.properties.code == "84" ) coord = feature.geometry.coordinates[1][0];
    var dept = new Region(coord);
    var pt = dept.centroid();
    this.points.push({lat:pt.y,lng:pt.x,text:feature.properties.code});
    // si c'est un dept selectionne on met de cote les coord pour pouvoir centrer la map ensuite
    if( this.state.selection.indexOf(feature.properties.code) != -1 ) {
      this.allCoord = this.allCoord.concat(coord);
    }
    // on a fini de traiter toutes les features

    // on regarde la taille de used - selection pour connaitre la fin du traitement
    var tabFilter = [];
    for(var d=0;d<this.state.used.length;d++) {
      if( this.state.selection.indexOf(this.state.used[d]) == -1 ) tabFilter.push(this.state.used[d])
    }
    var endCondition = GeoData.features.length-tabFilter.length;
    if( this.state.disabled || this.state.mode=='allZone' ) {
      endCondition = this.state.selection.length;
    }

    if( this.points.length == endCondition ) {
      var obj = {points:this.points};
      // on calcul le centroid des coord des dept selectionnes pour centrer la map
      if( this.allCoord.length > 0 ) {
        var xMin = 999, xMax = -999, yMin = 999, yMax = -999, pt = null;
        for(var i=0;i<this.allCoord.length;i++) {
          pt = {x:this.allCoord[i][0],y:this.allCoord[i][1]};
          if( pt.x < xMin ) xMin = pt.x;
          if( pt.x > xMax ) xMax = pt.x;
          if( pt.y < yMin ) yMin = pt.y;
          if( pt.y > yMax ) yMax = pt.y;
        }
      }
      this.setState(obj,()=>{
        if( this.allCoord.length > 0 ) {
          this._map.leafletElement.fitBounds([[yMin,xMin],[yMax,xMax]]);
        }
      });
    }

    var self = this;
    layer.on('click',(d)=>{
      if( this.state.mode == 'allZone' ) return;
      if( this.state.disabled ) return;
      var code = d.target.feature.properties.code;
      this.DeptClick(code);
    });
    layer.on('mouseover',(d)=>{
      console.log( 'mouseover !!!!!' );
      if( this.state.disabled ) return;
      if( self.state.mode == 'allZone' ) {
        var zone = self.state.zones;
        for(var i=0;i<zone.length;i++) {
          for(var j=0;j<zone[i].zone.length;j++) {
            if( zone[i].zone[j].code == d.target.feature.properties.code ) {
              console.log( 'overSelection:'+zone[i].name );
              this.setState({overSelection:zone[i].name});
            }
          }
        }
      }
      else {
        this.setState({overSelection:'('+d.target.feature.properties.code+') '+d.target.feature.properties.nom});
      }
    })
    layer.on('mouseout',(d)=>{
      if( this.state.disabled ) return;
      this.setState({overSelection:''});
    })
  }

  GeoFilter(feature) {
    var code = feature.properties.code;

    if( this.state.mode == 'allZone' ) {
      if( this.state.selection.indexOf(code) != -1 )
        return true;
      else
        return false;
    }

    if( this.state.disabled ) {
      if( this.state.selection.indexOf(code) != -1 )
        return true;
      else
        return false;
    }
    else {
      if( this.state.used.indexOf(code) != -1 ) {
        if( this.state.selection.indexOf(code) != -1 )
          return true;
        else
          return false;
      }
      else {
        return true;
      }
    }
  }

  DeptClick(code) {
    var tab = JSON.parse(JSON.stringify(this.state.selection));
    if( tab.indexOf(code) == -1 ) tab.push(code);
    else tab.splice(tab.indexOf(code),1);

    this.setState({selection:tab},()=>{
      if( StrDef(this.props.onChange) ) {
        var sel = [];
        for(var i=0;i<tab.length;i++) {
          sel.push({code:tab[i],name:this.tabCodeName[tab[i]]})
        }
        this.props.onChange(sel);
      }
    });
  }

  OnZoom(e) {
    if( this.state.mode == 'allZone' ) return;
    var disp = false, dispD = false;
    if( e.target._zoom > 5 ) disp = true;
    if( e.target._zoom > 4 ) dispD = true;
    this.setState({displayDeptLabel:disp,displayDept:dispD});
  }

  SetSize(w,h) {
    this.setState({width:w,height:h},()=>{
      this._map.leafletElement.invalidateSize();
    });
  }

  render() {
    const position = [this.state.lat, this.state.lng];

    return (
      <>
        <div style={{display:'flex',flexDirection:'column'}}>
          <div style={{position:'absolute',display:'flex',justifyContent:'center',alignItems:'center',zIndex:99999,marginLeft:'60px',width:'calc(100% - 120px)',height:'30px',backgroundColor:'transparent'}}>
            { StrDef(this.state.overSelection) &&
              <div style={{display:'flex',height:'30px',paddingLeft:'10px',paddingRight:'10px',justifyContent:'center',backgroundColor:'rgba(255,255,255,0.7)',borderRadius:0,borderBottomLeftRadius:'12px',borderBottomRightRadius:'12px'}}>
                <h6 style={{lineHeight:'30px'}}>{this.state.overSelection}</h6>
              </div>
            }
          </div>
          <Map  ref={(c)=>this._map=c}
                center={position} zoom={this.state.zoom}
                style={{width:this.state.width,height:this.state.height,borderRadius:this.props.mapRadius||'12px',textAlign:'center'}}
                onClick={this.OnClick}
                dragging={this.state.dragging}
                onZoom={this.OnZoom}
                zoomControl={this.state.disabled?false:true}
                doubleClickZoom={this.state.disabled?false:true}
                scrollWheelZoom={this.state.disabled?false:true}
                >
            <TileLayer
              attribution={TILE_ATTRIBUTION}
              url={TILE_PROVIDER_URL}
            />
            { this.state.displayDept &&
              <GeoJSON ref="GeoJSONLayer"
                data={GeoData}
                style={this.GeoStyle}
                onEachFeature={this.GeoEachFeature}
                filter={this.GeoFilter}/>
            }
            { this.state.displayDeptLabel && this.state.points.map((data,i)=>{
                var position = [data.lat,data.lng];
                var text = L.divIcon({html:data.text});
                return(
                  <Marker key={'marker'+i} position={position} icon={text} onClick={this.MarkerClick.bind(this,data.text)}></Marker>
                );
              })
            }
            { false &&
            <div style={{position:'sticky',display:'flex',justifyContent:'center',alignItems:'center',zIndex:99999,marginLeft:'60px',width:'calc(100% - 120px)',height:'30px',backgroundColor:'transparent'}}>
              { StrDef(this.state.overSelection) &&
                <div style={{display:'flex',height:'30px',paddingLeft:'10px',paddingRight:'10px',justifyContent:'center',backgroundColor:'rgba(255,255,255,0.7)',borderRadius:0,borderBottomLeftRadius:'12px',borderBottomRightRadius:'12px'}}>
                  <h6 style={{lineHeight:'30px'}}>{this.state.overSelection}</h6>
                </div>
              }
            </div>
            }
          </Map>

        </div>
      </>
    );
  }
}

export default OMap;
