import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from "reactstrap";

import i18n from "../../assets/lib/i18n";
import {sharedAPI} from "../../assets/lib/api";
import {StrDef,Phone,GetJSON} from '../../assets/lib/utils';
import Select from "react-select";
import moment from 'moment';
import grape from '../../assets/data/cepage.json';
import appellation from '../../assets/data/appellation.json';

class Agents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prod_id:'',
      name:'',
      nameState:'',
      code:'',
      codeState:'',
      appellation:'',
      appellationState:'',
      grape:'',
      grapeState:'',
      modalVintage:false,
      modalDelete:false,
    };

    this.backend = sharedAPI();

    this.EventRefresh = this.EventRefresh.bind(this);
    this.Title = this.Title.bind(this);
    this.Change = this.Change.bind(this);
    this.AddVintage = this.AddVintage.bind(this);
    this.Delete = this.Delete.bind(this);
    this.DeleteConfirm = this.DeleteConfirm.bind(this);


    // les cepages
    grape.sort();
    this.grapeList = [];
    for(var i=0;i<grape.length;i++) {
      this.grapeList.push({label:grape[i],value:i});
    }
    // les appellations
    appellation.sort();
    this.appellationList = [];
    for(var i=0;i<appellation.length;i++) {
      this.appellationList.push({label:appellation[i].appellation,value:i});
    }
    // load data
    if( StrDef(this.props.match.params.prod_id) && this.props.match.params.prod_id!='add' ) {
      this.state.prod_id = this.props.match.params.prod_id;
      this.LoadData();
    }
  }

  componentDidMount() {
    document.removeEventListener('refresh', this.EventRefresh, false);
    document.addEventListener('refresh', this.EventRefresh);
  }

  componentWillUnmount() {
    document.removeEventListener('refresh', this.EventRefresh, false);
    this.Save();
  }

  LoadData() {
    this.backend.kv_group_get({group:'WINE',key:this.state.prod_id},(res)=>{
      if( StrDef(res.error) ) {
        window.notify(i18n.t('all.error'),'danger');
      }
      else {
        var tmp = res[0].parameter_value;
        this.setState({
          name:tmp.name,
          code:tmp.code,
          grape:tmp.grape,
          appellation:tmp.appellation,
        });
      }
    });
  }

  Save() {
    var prod_id = moment().format('YYYYMMDDHHmmssSSS');
    var data = {prod_id:prod_id};
    data.name = this.state.name;
    data.code = this.state.code;
    data.appellation = this.state.appellation;
    data.grape = this.state.grape;

    var callback = (res)=>{
      if( StrDef(res.error) ) {
        window.notify(i18n.t('all.error'),'danger');
      }
      else {
        console.log('wine saved successfully');
      }
      document.dispatchEvent(new Event('refreshWineList'));
    };

    if( StrDef(data.name) && StrDef(data.appellation) && StrDef(data.grape) ) {
      if( !StrDef(this.state.prod_id) ) { // on est en creation !!!
        this.backend.kv_group_post({group:'WINE',key:data.prod_id,value:data},callback);
      }
      else {
        data.prod_id = this.state.prod_id;
        this.backend.kv_group_put({group:'WINE',key:data.prod_id,value:data},callback);
      }
    }
  }

  EventRefresh() {
  }

  Title() {
    var res = i18n.t('wine.title');
    if( this.props.match.params.prod_id == 'add' ) res = i18n.t('wine.titleAdd');
    if( StrDef(this.state.name) ) res = this.state.name;
    return res;
  }

  Change(what,t) {
    if( StrDef(t) && StrDef(t.target) && StrDef(t.target.value) ) t = t.target.value;
    var obj = {};
    obj[what] = t;
    this.setState(obj);
  }

  AddVintage() {
    this.setState({modalVintage:!this.state.modalVintage});
  }

  Delete() {
    this.setState({modalDelete:!this.state.modalDelete});
  }

  DeleteConfirm() {
    this.backend.kv_group_del({group:'WINE',key:this.state.prod_id},(res)=>{
      if( StrDef(res.error) ) {
        window.notify(i18n.t('all.error'),'danger');
      }
      else {
        this.setState({prod_id:'',name:'',appellation:'',grape:''},()=>{
          this.props.history.goBack();
        });
        console.log('wine deleted successfully');
      }
      document.dispatchEvent(new Event('refreshWineList'));
    });
  }

  render() {
    return (
      <>
        <div className="content">

          <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'30px'}}>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Button className="btn-link" color="primary" style={{margin:'0px',width:'44px',height:'44px',borderRadius:'50%'}} onClick={()=>this.props.history.goBack()}>
              <i className="nc-icon nc-minimal-left"/>
            </Button>
            </div>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:'20px'}}>
              <h3 style={{marginBottom:'0px'}}>{this.Title()}</h3>
            </div>
          </div>

          <Row>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">{i18n.t('wine.info')}</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup className={`has-label ${this.state.nameState}`}>
                    <label>{i18n.t('wine.name')}</label>
                    <Input
                      autoComplete="off"
                      value={this.state.name}
                      onChange={this.Change.bind(this,'name')}
                    />
                    {this.state.nameState==="has-danger"?(<label className="error">{i18n.t('wine.nameError')}</label>):null}
                  </FormGroup>

                  <FormGroup className={`has-label ${this.state.codeState}`}>
                    <label>{i18n.t('wine.code')}</label>
                    <Input
                      autoComplete="off"
                      value={this.state.code}
                      onChange={this.Change.bind(this,'code')}
                    />
                    {this.state.codeState==="has-danger"?(<label className="error">{i18n.t('wine.codeError')}</label>):null}
                  </FormGroup>

                  <FormGroup className={`has-label ${this.state.appellationState}`}>
                    <label>{i18n.t('wine.appellation')}</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={this.state.appellation}
                      defaultValue={this.state.appellation}
                      onChange={this.Change.bind(this,'appellation')}
                      options={this.appellationList}
                      placeholder={i18n.t('wine.appellationPlaceholder')}
                    />
                    {this.state.appellationState==="has-danger"?(<label className="error">{i18n.t('wine.appellationError')}</label>):null}
                  </FormGroup>

                  <FormGroup className={`has-label ${this.state.grapeState}`}>
                    <label>{i18n.t('wine.grape')}</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      isMulti
                      value={this.state.grape}
                      defaultValue={this.state.grape}
                      onChange={this.Change.bind(this,'grape')}
                      options={this.grapeList}
                      placeholder={i18n.t('wine.grapePlaceholder')}
                    />
                    {this.state.grapeState==="has-danger"?(<label className="error">{i18n.t('wine.grapeError')}</label>):null}
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">{i18n.t('wine.vintage')}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Button color="primary" onClick={this.AddVintage}>{i18n.t('wine.vintageAdd')}</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          { StrDef(this.state.prod_id) &&
            <Row>
              <Col md="12">
                <Button className="btn-link" color="danger" onClick={this.Delete}>{i18n.t('wine.delete')}</Button>
              </Col>
            </Row>
          }

          <Modal isOpen={this.state.modalVintage} toggle={this.AddVintage} backdrop={true}>
            <ModalHeader>
              {i18n.t('wine.vintage')}
            </ModalHeader>
            <ModalBody>
              ajouter millesimes<br/>
              commentaires sur météo, % cépages ? .....
            </ModalBody>
          </Modal>

          { StrDef(this.state.prod_id) &&
            <Modal isOpen={this.state.modalDelete} toggle={this.Delete} backdrop={true}>
              <ModalHeader>{i18n.t('wine.deleteConfirm').replace('###',this.state.name)}</ModalHeader>
                <ModalBody>

                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={this.DeleteConfirm}>{i18n.t('all.delete')}</Button>
                  <Button color="neutral" onClick={this.Delete}>{i18n.t('all.cancel')}</Button>
                </ModalFooter>
            </Modal>
          }

        </div>
      </>
    );
  }
}

export default Agents;
