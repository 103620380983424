import {getLocale,preimageSha256Condition} from './utils';
import axios from 'axios';
import {StrDef} from './utils';
import _ from "lodash";
var jwtDecode = require('jwt-decode');
var moment = require('moment');
var stablejsonstringify = require('json-stable-stringify');
var sha3hash = require('js-sha3');
var uuid = require('uuid');

const API_KEY = '';
let _api = null;

export function getHashFromJson(json_data) {
  json_data = stablejsonstringify(json_data, (a, b) => (a.key > b.key ? 1 : -1))
  return sha3hash.sha3_256.create().update(json_data).hex()
}

/**
 * ⚠️ Attention : si vous souhaitez changer le script en PROD
 * ou en PREPROD: 
 * - Changez la baseURL dans les options Axios à la ligne 27. 
 * - Décommentez / Commentez les lignes 533 et 535.
 * 
 * Enjoy ! 🚀
 */

export default class Api {
  constructor() {
    this.api = axios.create({
      //baseURL: "http://safethingsloadbalancernew-2088605042.eu-west-1.elb.amazonaws.com/", //preprod
      // baseURL: 'https://dev.ocode.team/',
      baseURL: '/',
      timeout: 10000
    });

    this.API_TIMEOUT_GET = 30000;

    if(_api != null) {
        throw 'There is already an instance of api alive';
    }

    if( localStorage.getItem('user') !== null ) {
      var user = JSON.parse(localStorage.getItem('user'));
      this.accessToken = user.accessToken;
      this.refreshToken = user.refreshToken;
      this.userId = user.userId;
    }


    _api = this;
    //this.token = '';
  }

  isTokenExpired(token) {
    var decoded = null;
    try {
      decoded = jwtDecode(token);
    }
    catch(e) {
      console.log(">>>> isTokenExpired",e);
      return true;
    }
    return decoded.exp < parseInt(moment.utc().valueOf()/1000);
  }

  async  get_new_accessToken(force) {
    var res = null;
    // si la version n'est déjà pas bonne ne pas faire cette opération
    var user = JSON.parse(localStorage.getItem('user'));
    var refreshToken = user.refreshToken;
    let isExpired = this.isTokenExpired(refreshToken)
    if( isExpired && !Defined(force) ) {
      //this.navigator.handleDeepLink({link: "login/required"});
      console.log("aieieaieieaieieaieieaieieaieieaieieaieieaieieaieieaieieaieieaieie");
      return '';
    } else {
      try {
        res = await this.api.get('authenticationapi/signin',{ headers: {'accept-version': '1.0.0',
                                                                        /*'x-mobile-api-key' : API_KEY,*/
                                                                        /*'Content-Encoding': 'gzip',*/
                                                                        'Content-Type': 'application/json',                                                                    'Authorization': 'Bearer '+ refreshToken} })
      }
      catch(e) {
        console.log("eeee",e.response.data.message);
        if (e.response.data.message === 'Refresh token is revoked.') {
          console.log("return vide");
          var event = new Event('logout');
          document.dispatchEvent(event);
          return '';
        } else if( e.response === undefined ) {
          // cas de pas internet par exemple ou serveur inaccessible
          // if( Defined(this.navigator.GetScreen()) )
          //   this.navigator.Alert.show({kind:'ERROR',title:I18n.t('notif.noNetworkTitle2'),msg:I18n.t('notif.noNetworkMsg2')});
        }

        else if( Defined(e.response) && e.response.status===400 ) {  // refresh token revoked on logout si on n'est pas deja logouté
          return;
        }
      }

      if( Defined(res) && Defined(res.data) && Defined(res.data.access_token) ) {
        console.log("token res",res);
        user = JSON.parse(localStorage.getItem('user'));
        user.accessToken = res.data.access_token;
        console.log( 'get_new_accessToken = ', user );
        localStorage.setItem('user',JSON.stringify(user));
        // this.accessToken = res.data.access_token;
        //var decoded = null;
        return res.data.access_token;
      }
      else {
        console.log('oupsoupsoupsoupsoupsoupsoupsoupsoupsoupsoups')
        return '';
      }
    }
  }

  async GetAccessToken() {
    let isExpired = this.isTokenExpired(this.accessToken)
    if( isExpired ) {
      return await this.get_new_accessToken(true)
    }
    else {
      return this.accessToken;
    }
  }

  async GET(query,config) {
    //var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+ accessToken,
                          'accept-version': '1.0.0',
                          /*'x-mobile-api-key' : API_KEY,*/
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    } else {
      conf = { headers: { 'accept-version': '1.0.0',
                          /*'x-mobile-api-key' : API_KEY,*/
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
      conf.headers['Authorization'] = config.headers.Authorization;
    }
    return this.api.get(query,conf)
        .then(function (response) {
          if( response.status === 200 ) {
            return response.data;
          }
          else {
            return ({error:''});
          }
        })
        .catch(async function (error) {
          if (error.response.data && error.response.data.code) {
            return {error: {...error.response.data, userMessage: "Ce O°Code est inconnu."}};
          }
          localStorage.removeItem('user')
          window.location = '/'
          //return null;
          /*var event = new Event('logout');
          document.dispatchEvent(event);*/
          // return ({error:error.response.data.code});
        });
  }

  async POST(query,data,config) {
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          /*'x-mobile-api-key' : API_KEY,*/
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          /*'x-mobile-api-key' : API_KEY,*/
                          /*'Accept-Encoding': 'gzip',
                          'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
      if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
        conf.headers['Authorization'] = config.headers.Authorization;
      }
    }
    return this.api.post(query, data, conf)
      .then(function (response) {
        if( response.status === 200 ) {
          return(response.data);
        }
        else {
          return {error:''};
        }
      })
      .catch(async function (error) {
        if (error.response) {
          if( error.response.status === 400 ) {
            if( error.response.data.code === 'NotAuthorizedException'
                && query !== 'authenticationapi/signin'
                && query !== 'authenticationapi/signin_ckeck'
                && query !== 'authenticationapi/register_device'
                && query !== 'authenticationapi/forgotpassword') {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                return await self.POST(query,data,config);
              } else {
                return ({error:true});
              }
            }
            else {
              return {error:error.response.data};
            }
          }
          return( {error:error.response.data} );
        } else {

        }
        return {error:'unknown'};
      });
  }

  async PUT(query,data,config) {
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          /*'x-mobile-api-key' : API_KEY,*/
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          /*'x-mobile-api-key' : API_KEY,*/
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
      conf.headers['Authorization'] = config.headers.Authorization;
    }

    return this.api.put(query, data, conf)
      .then(function (response) {
        if( response.status === 200 ) {
          return(response.data);
        }
        else {
          return {error:''};
        }
      })
      .catch(async function (error) {
        console.log("error put",error.response);
        if (error.response) {
          if( error.response.status === 400 ) {
            console.log( 'put err 1' ,error.response.data.code);
            if( error.response.data.code === 'NotAuthorizedException' && query !== 'authenticationapi/password' && query !== 'authenticationapi/kpis' ) {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                return await self.PUT(query,data,config);
              } else {
                console.log("logoutlogoutlogoutlogoutlogoutlogoutlogout");
                /*var event = new Event('logout');
                document.dispatchEvent(event);*/
                return ({error:true});
              }
            }
          }
          console.log( 'put err 2' );
          return( {error:error.response.data.code} );
        } else {
        }
        return {error:''};
      });
  }

  async DELETE(query,config) {
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      if( accessToken === '' ) return null;
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          /*'x-mobile-api-key' : API_KEY,*/
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          /*'x-mobile-api-key' : API_KEY,*/
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }

    return this.api.delete(query,conf)
        .then(function (response) {
          if( response.status === 200 ) {
            return response.data;
          }
          return {error:response.status};
        })
        .catch(async function (error) {
          if( error.response.status === 400 ) {
            if( error.response.data.code === 'NotAuthorizedException' ) {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                return await self.DELETE(query,config);
              }else {
                console.log("logoutlogoutlogoutlogoutlogoutlogoutlogout");
                /*var event = new Event('logout');
                document.dispatchEvent(event);*/
                return ({error:true});
              }
            }
          }
          return {error:error.response.status};
        });
  }

  //------------------------------------------------ API CALLS
  signin(token) {
    try {
      let user
      if (localStorage.getItem('user')) {
        user = JSON.parse(localStorage.getItem('user'))
      }
      // console.log(user)
      var data = jwtDecode(token);
      this.accessToken = token;
      this.refreshToken = user.refreshToken || null;
      this.userId = data.user_id;
      localStorage.setItem('user',JSON.stringify({
        accessToken: this.accessToken,
        refreshToken: user.refreshToken || null,
        userId: this.userId
      }));
      return true;
    }
    catch(e) {
      console.log('Invalid token');
      return {error:'invalid token'};
    }
  }

  //============================================ STORIES ===============
  async storyInit(story_id,contact_id,callback) {
    var before = moment().add(10,'days').format('YYYY-MM-DD%20HH:mm:ss');
    var res = null;
    var tab = [];
    var hasMore = true;
    while(hasMore) {
      res = await this.storyEvents(before,story_id,contact_id);
      tab = tab.concat(res.data);
      hasMore = res.hasMore;
      before = res.before;
    }
    callback(tab);
  }

  async storyEvents(before,story_id,contact_id) {
    var res = await this.GET('safethingsapi/thingstory/'+story_id+'/events?created_before='+before);
    if (!res) res = []
    var last = null, already = 0;
    var tab = [], tabId = [];
    for(var i=0;i<res.length;i++) {
      last = res[i].create_date;
      if( res[i].contact_id == contact_id ) {
        if( tabId.indexOf(res[i].event_id) == -1 ) {
          already++;
          tab.push( res[i] );
          tabId.push( res[i].event_id );
        }
      }
    }
    return {data:tab,before:last,hasMore:res.length==20};
  }

  async storySend(data,callback) {
    if( !StrDef(data.text) && !Defined(data.geo) ) return;
    var obj = {
      whoami: "Finder",
      follow: 1,
      event_local_id: data.id,
      when: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      description: {}
    }

    // Ajouter le recipient_id
    obj.description.recipient_id = data.contact_id

    if ( StrDef(data.text) ) {
      obj.event_type = "MESSAGE"
      obj.description.short_text = data.text
    } else if ( Defined(data.geo) ) {
      obj.event_type = "PEOPLEGEOLOC" // "THINGGEOLOC" -> Obsolète
      obj.description.geolocation = {}
      obj.description.geolocation.latitude = data.position.latitude
      obj.description.geolocation.longitude = data.position.longitude
    }

    var res = await this.POST('safethingsapi/thingstory/'+data.story_id+'/event',obj);
    callback(res);
  }

  async lastVersion(concept) {
    var res = await this.GET('safethingsapi/userconcepts');
    var last = -1;
    if( StrDef(res) && !StrDef(res.error) ) {
      for(var i=0;i<res.length;i++) {
        if( String(res[i].concept) == String(concept) ) {
          last = parseInt(res[i].last_known_version);
        }
      }
    }
    return last;
  }

  async get_public_photo(name) {
    var res = await this.GET('safethingsapi/media/'+name);

    if( Defined(res) ) {
      if( Defined(res.url) ) {
        return res.url;
      }
    }
    else {
      return '';
    }
  }

  //============================================ REGISTER ===============
  /**
   * @private
   */
  async initRegisterDevice () {
    const body = {
      app: "FollowMeBackEnd",
      device_token: moment().utc().valueOf(),
      device_platform: "browser"
    }
    const query = "authenticationapi/init_register_device"
    try {
      return await this.POST(query, body, {})
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * @private
   * @param  {string} register_token
   */
  async registerDevice (register_token) {
    const config = {
      headers: {
        "accept-version": "1.0.0",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + register_token
      }
    }
    const body = {
      country_id: "FR",
      locale: "fr-FR",
      password: "averycomplexpasswordisntit"
    }
    const query = "authenticationapi/register_device"
    return await this.POST(query, body, config)
  }

  /**
   * @private
   * @param  {string} ocode
   */
  async getMooncode (ocode) {
    const query = "safethingsapi/mooncode/" + ocode

    return await this.GET(query);
  }

  // Scanner un O°Code pour se rendre sur le chat
  async connectToChat (ocode) {
    if (!this.accessToken || !this.refreshToken) {
      try {
        const registerData = await this.initRegisterDevice()
        let loginData = await this.registerDevice(registerData.register_token)
        loginData = _.pick(loginData, [ "access_token", "refresh_token", "user_id" ])
        localStorage.setItem("user", JSON.stringify({
          accessToken: loginData.access_token,
          refreshToken: loginData.refresh_token,
          userId: loginData.user_id
        }))
        this.accessToken = loginData.access_token
        this.refreshToken = loginData.refresh_token
        this.userId = loginData.user_id
      } catch (err) {
        console.error("Impossible de se connecter :(")
        console.error(err)
        localStorage.removeItem("user") // Tant pis, on créera un nouvel utilisateur...
      }
    }

    const ocodeData = await this.getMooncode(ocode)
    if (ocodeData.error) {
      return ocodeData
    } else if(ocodeData.scope && ocodeData.scope === "OBIKEOCODE") {
      // Preprod
      // return await this.GET(`https://dev.ocode.team/obikeapi/get_bike_id/${ocode}`)
      // Prod
      return await this.GET(`https://scan-ocode.com/obikeapi/get_bike_id/${ocode}`)
    } else if (ocodeData.is_available) {
      return "No Owner"
    }

    const story_id = ocodeData.owner_data.detail.thingstory_id
    const url = `/${story_id}/${ocodeData.owner_id}/${this.accessToken}`
    return url
  }
}

function Defined(obj) {
 return typeof(obj) != 'undefined' && obj != null;
}
/*function StrDef(obj) {
  return typeof(obj) != 'undefined' && obj != null && obj != '';
}*/

/**
 * @returns {Api}
 */
export function sharedAPI() {
  return _api;
}
