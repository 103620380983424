import Dashboard from "views/grower/Dashboard.jsx";
import Wines from "views/grower/Wines.jsx";
import Wine from "views/grower/Wine.jsx";


import i18n from "./assets/lib/i18n";

const routes = [
  {
    path: "/dashboard",
    name: i18n.t('sidebar.dashboard'),
    icon: "home",
    component: Dashboard,
    layout: "/grower"
  },
  {
    path: "/orders",
    name: i18n.t('sidebar.order'),
    icon: "orders",
    component: Dashboard,
    layout: "/grower"
  },
  {
    path: "/wines",
    name: i18n.t('sidebar.wine'),
    icon: "wines",
    component: Wines,
    layout: "/grower"
  },
  {
    path: "/wine/:prod_id",
    component: Wine,
    layout: "/grower",
    hidden:true
  },
];

export default routes;
