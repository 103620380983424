import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "./layouts/Auth/Auth.jsx";
import ScanOcodeLayout from "./layouts/ScanOcode/ScanOcode.jsx";
import AdminLayout from "./layouts/Admin/Admin.jsx";
import AuthRoute from "./components/AuthRoute/AuthRoute.jsx";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss";
import "./assets/demo/demo.css";
import "./assets/scss/owine.scss";
import "./assets/scss/v2/webappV2.scss";

import Api from './assets/lib/api';

const hist = createBrowserHistory();

// on instancie la classe API qui gère les appels aux serveurs
new Api();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/:story/:finder/:token" render={props => <AuthLayout {...props} />} />
      <Route path="/sorry" render={props => <AuthLayout {...props} />} />
      <Route path="/" render={props => <ScanOcodeLayout {...props} />} />
      <Redirect to="/sorry"/>
    </Switch>
  </Router>,
  document.getElementById("root")
);
