import React from "react";
import { Route, Redirect } from "react-router-dom";
import {StrDef} from "../../assets/lib/utils";

export default ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props => {

      var authenticated = sessionStorage.getItem('isAuth')==='1';

      if( authenticated ) {
        return <C {...props} {...cProps} />
      }
      else {
        return <Redirect to={{pathname: '/home', state: {from: props.location}}}/>
      }
    }}/>;
