import {sharedAPI} from './api';
import XLSX from 'xlsx';
import Fuse from 'fuse.js';
import moment from 'moment';
const cc = require('five-bells-condition');
var _ = require('underscore');

export function getOS() {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}


export function SortBy(tab,prop) {
  return _.sortBy( tab, prop );
}


export function GetJSON(url,callback) {
  var request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      var data = JSON.parse(request.responseText);
      callback(data);
    } else {
      callback({error:'unknown'});
    }
  };
  request.onerror = function() {
    callback({error:'network error'});
  };
  request.send();
}

function Point(x, y) {
  this.x = x;
  this.y = y;
}

export function Region(points) {
  this.points = points || [];
  this.length = points.length;
}

Region.prototype.area = function () {
  var area = 0,
      i,
      j,
      point1,
      point2;

  for (i = 0, j = this.length - 1; i < this.length; j = i, i += 1) {
      point1 = this.points[i];
      point2 = this.points[j];
      area += point1[0] * point2[1];
      area -= point1[1] * point2[0];
  }
  area /= 2;

  return area;
};

Region.prototype.centroid = function () {
    var x = 0,
        y = 0,
        i,
        j,
        f,
        point1,
        point2;

    for (i = 0, j = this.length - 1; i < this.length; j = i, i += 1) {
        point1 = this.points[i];
        point2 = this.points[j];
        f = point1[0] * point2[1] - point2[0] * point1[1];
        x += (point1[0] + point2[0]) * f;
        y += (point1[1] + point2[1]) * f;
    }

    f = this.area() * 6;

    return new Point(x / f, y / f);
}

export function StrDef(s) {
  return ( typeof(s) != 'undefined' && s !== null && s !== '' );
}

export function Phone(s) {
  var phoneNumber = s;

  if( StrDef(phoneNumber) ) {
    var formatted = phoneNumber.replace(/(\d{1})(\d{1,3})?(\d{1,3})?(\d{1,3})?/, function(_, p1, p2, p3, p4){
      let output = ""
      if (p1) output = `(${p1})`;
      if (p2) output += ` ${p2}`;
      if (p3) output += ` ${p3}`;
      if (p4) output += ` ${p4}`;
      return output;
    });
  }
  return formatted;
}

export function exportXLSX(jsonArray,titleSheet,titleFile) {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(jsonArray);
  XLSX.utils.sheet_add_json(ws,
    [

    ],
    {
      skipHeader: true,
      origin: -1,  // append to bottom of worksheet starting on first column
    }
  );

  XLSX.utils.book_append_sheet(wb, ws, titleSheet);
  XLSX.writeFile(wb, titleFile);
}

export function removeAccents(str) {
  let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split('');
  str.forEach((letter, index) => {
    let i = accents.indexOf(letter);
    if (i !== -1) {
      str[index] = accentsOut[i];
    }
  })
  return str.join('');
}

export function ValidateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function generatePassword() {
 var length = 5,
     charset = "0123456789",
     retVal = "";
 for (var i = 0, n = charset.length; i < length; ++i) {
     retVal += charset.charAt(Math.floor(Math.random() * n));
 }
 return retVal;
}

export function preimageSha256Condition(password) {
 var preimage = generatePassword();
 if( typeof(password) != 'undefined' ) preimage = password;
 var result = {};
 try {
   var condition = new cc.PreimageSha256()
   condition.setPreimage(new Buffer(preimage));
   result = {
     uri: condition.getConditionUri(),
     name: 'password',
     detail: {
       type: 'preimage-sha-256'
     }
   };
 }
 catch (err) {
     console.log(err);
 }
 return result
}

// ======================================================== KeyValueStore ======
export class KVStore {
  constructor(props) {
    console.log( 'KVStore constructor !!!!!', props );

    this.group = props.group;
    this.tab = [];                // c'est le tableau qui contient les values

    this.synchro();
  }

  synchro(callback) {
    var self = this;
    var lastLocal = moment().subtract(100,'years').format('YYYY-MM-DD HH:mm:ss');
    // on load depuis le localstorage
    var data = localStorage.getItem(this.group);
    if( data !== null ) {
      this.tab = JSON.parse(data);
      for(var i=0;i<this.tab.length;i++) {
        if( this.tab[i].modify_date > lastLocal ) lastLocal = this.tab[i].modify_date;
      }
    }
    else {
      this.tab = [];
    }
    // on va voir si on est synchro
    var backend = sharedAPI();
    var lastRemote = moment().format('YYYY-MM-DD HH:mm:ss').replace(' ','%20');

    var fetchRemote = ()=>{
      backend.kv_group_get(this.group,lastRemote,(d)=>{
        if( d.length > 0 ) {
          for(var i=0;i<d.length;i++) {
            self.tab.push(d[i]);
            lastRemote = d[i].modify_date;
          }
          if( lastRemote > lastLocal && d.length==20 ) {
            fetchRemote();
          }
          else {
            localStorage.setItem(this.group,JSON.stringify(this.tab));
          }
        }
      })
    };
    fetchRemote();
  }

  setItem(obj) {
    // on met a jour le cache
    var pos = _.findIndex(this.tab,{id:obj.id});
    this.tab[pos] = JSON.parse(JSON.stringify(obj));
    // on met a jour l'api
    var backend = sharedAPI();
    backend.kv_group_put(this.group,obj,(r)=>{});
  }

  findFuzzItem(keys,search,threshold) {       // threshold : 0 == perfect match, 0.6 : default for fuzzySearch
    var th = 0.6;
    if( typeof(threshold) != 'undefined' ) th = threshold;
    var fuse = new Fuse(this.tab,{keys:keys,threshold:th});
    return fuse.search(search).slice(0,10);
  }
}

//========================================================= Currency ===========
export function price(n) {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })

  return formatter.format(n);
}

export function getLocale(length) {
  var locale = '';
  if (window.navigator.languages) {
      locale = window.navigator.languages[0];
  } else {
      locale = window.navigator.userLanguage || window.navigator.language;
  }
  var tiret = locale.indexOf('-');
  if( tiret != -1 ) {
    var tmp = locale.split('-');
    locale = tmp[0].toLowerCase()+'-'+tmp[1].toUpperCase();
  }
  else {
    locale = locale.toLowerCase()+'-'+locale.toUpperCase();
  }
  if( length === 2 ) locale = locale.substr(0,2).toUpperCase();
  return locale;
}
