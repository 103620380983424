import React, { Component } from 'react';

class SendIcon extends Component {

  render() {
    return (
    <button
      onFocus={this.props.onFocus}
      onBlur={this.props.onBlur}
      onClick={(e) => { e.preventDefault(); this.props.onClick(e); }}
      className="sc-user-input--send-icon-wrapper"
    >
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V31C0 33.7614 2.23858 36 5 36H31C33.7614 36 36 33.7614 36 31V5C36 2.23858 33.7614 0 31 0H5ZM26.359 9.0193C26.5142 9.05325 26.6565 9.13075 26.7691 9.24268C26.8783 9.35694 26.9528 9.49985 26.9838 9.65477C27.0148 9.80968 27.0011 9.97021 26.9443 10.1176L20.2699 28.45C20.2126 28.6084 20.1085 28.7458 19.9715 28.844C19.8345 28.9421 19.6709 28.9965 19.5023 29C19.3352 29.0002 19.1719 28.9502 19.0336 28.8566C18.8952 28.763 18.7882 28.6301 18.7264 28.475L15.8564 21.3171L21.1709 16.009L19.9945 14.8341L14.7051 20.1338L7.53842 17.2756C7.38012 17.2156 7.24384 17.109 7.14767 16.9698C7.05151 16.8307 7 16.6656 7 16.4965C7 16.3274 7.05151 16.1623 7.14767 16.0231C7.24384 15.884 7.38012 15.7773 7.53842 15.7174L25.893 9.05103C26.0422 8.99636 26.2038 8.98535 26.359 9.0193Z" fill="#26CFAD"/>
      </svg>


      {/* <svg
        version='1.1'
        className='sc-user-input--send-icon'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        width='37.393px'
        height='37.393px'
        viewBox='0 0 48 48'
        enableBackground='new 0 0 37.393 37.393'>
        <g id='Layer_2'>
          <path d="M36.78,23.5l-25-14.42a.58.58,0,0,0-.86.64l4,10.41a1.83,1.83,0,0,0,1.32,1.12l10.29,2.19a.57.57,0,0,1,0,1.12L16.28,26.75A1.83,1.83,0,0,0,15,27.87L11,38.28a.58.58,0,0,0,.86.64l25-14.42A.58.58,0,0,0,36.78,23.5Z" />
        </g>
      </svg> */}
    </button>
    );
  }
}

export default SendIcon;
