import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Link, Route, Switch } from "react-router-dom";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import {Button,
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        Card,
        CardHeader,
        CardBody,
        CardFooter,
        CardTitle,
        Label,
        FormGroup,
        Form,
        Input,
        Row,
        Col,
        } from 'reactstrap';
import {StrDef,getOS,SortBy} from '../../assets/lib/utils';
import i18n from "../../assets/lib/i18n";
import {sharedAPI} from "../../assets/lib/api";
import NotificationAlert from "../../components/Notification/Notification.jsx";
import moment from 'moment';
import {Launcher} from '../../components/react-chat-window';
import OMap from '../../components/Map/OMap.jsx';
const isMobile = require('ismobilejs');


var ps;

const theme = {};
theme.orange        = '#FCBF17';
theme.blue          = '#FFFFFF';


const MAX_BLOC  = 5;  // au bout de MAX_BLOC messages d'affilés on doit attendre une réponse
const MAX_GLOBAL_PHOTO = 10;  // au bout de MAX_GLOBAL_PHOTO photo on doit interdire l'envoi de nouvelle photo
const INIT_PLACEHOLDER = i18n.t('screenChat.surprise');
const STORY_SYNC_TIMEOUT = 3000;

class Pages extends React.Component {
  constructor(props) {
    super(props);

    this.notify = this.notify.bind(this);
    this.ModalPhoto = this.ModalPhoto.bind(this);

    this.ComeFrom = '';

    this.state = {
      messageList: [],
      modalPhoto:false,
      waitingForResponse:false,
    };

    this.backend = sharedAPI();
    window.notify = this.notify;

    const token = this.backend.accessToken;

    this.state.story_id = this.props.match.params.story;
    this.state.contact_id = this.props.match.params.finder;
    this.state.token = token || this.props.match.params.token;

    var auth = this.backend.signin(this.state.token);
    console.log( auth );
    if( auth === true ) {
      this.backend.storyInit(this.state.story_id,this.state.contact_id,this.StoryInit.bind(this));
      this.state.authentified = true;
    }
    else {
      this.state.authentified = false;
    }
  }

  notify(msg,type) {
    this.refs.notificationAlert.notificationAlert({ message: msg, type: type });
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1 && StrDef(this.refs.fullPages)) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }

    // on regarde si on ne vient pas de se faire "logouter", par consequent il faut rediriger vers d'ou on vient apres le login
    if( StrDef(this.props.location) && StrDef(this.props.location.state) && StrDef(this.props.location.state.from) ) {
      this.ComeFrom = this.props.location.state.from.pathname+this.props.location.state.from.search;
      console.log( 'this.ComeFrom = ', this.ComeFrom );
      this.setState({modalLogin:true});
    }
    else {
      this.ComeFrom = '';
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  async StoryEventToMessage(e) {
    console.log( "TESTESTETESTETSTES => ", e.description );
    var msg = null;
    if( e.description.whois_sender == 'OWNER' ) {
      msg = {
        type:'text',
        author:'other',
        data: {user:'Owner',ts:e.description.when,id:e.event_local_id,parent:this}
      };
      // message text
      if( StrDef(e.description.short_text) ) {
        msg.data.text = e.description.short_text;
      }
      // geoloc
      if( StrDef(e.description.geolocation) ) {
        msg.data.geo = true;
        msg.data.onclick = JSON.parse(JSON.stringify(e.description.geolocation));
      }
      // photo
      if( StrDef(e.description.media) ) {
        msg.data.photo = await this.backend.get_public_photo(e.description.media.name);
        msg.data.onclick = msg.data.photo;
      }
    }
    else {
      msg = {
        type:'text',
        author:'me',
        data: {user:'Finder',ts:e.description.when,id:e.event_local_id}
      };
      // message text
      if( StrDef(e.description.short_text) ) {
        msg.data.text = e.description.short_text;
      }

      // geoloc
      if( StrDef(e.description.geolocation) ) {
        msg.data.parent = this
        msg.data.geo = true;
        msg.data.onclick = JSON.parse(JSON.stringify(e.description.geolocation));
      }
    }
    return msg;
  }

  async StoryInit(data) {
    var tab = [], msg = '', lastVersion = -1;
    for(var i=0;i<data.length;i++) {
      // on garde la plus grande version des event recus
      if( data[i].description.whois_sender == 'OWNER' ) {
        if( parseInt(data[i].row_version) > lastVersion ) lastVersion = parseInt(data[i].row_version);
      }
      tab.push( await this.StoryEventToMessage(data[i]) );
    }
    this.setState({messageList:tab.reverse(),lastVersion:lastVersion},()=>{
      this.CheckRelou();
      this.StorySync();
    });
  }

  async StorySync() {
    var lastVersion = await this.backend.lastVersion(5);
    console.log("lastVersion", lastVersion)
    console.log("this.state.lastVersion", this.state.lastVersion)
    if( lastVersion > this.state.lastVersion ) {  // si on n'est pas a jour on recupere les 20 derniers messages
      var res = await this.backend.storyEvents(moment().add(10,'days').format('YYYY-MM-DD%20HH:mm:ss'),this.state.story_id,this.state.contact_id);
      if( StrDef(res) ) {
        // on recupere tous les id qu'on a
        var tabId = []
        for(var i=0;i<this.state.messageList.length;i++) {
          tabId.push(this.state.messageList[i].data.id);
        }
        res = res.data;
        for(var i=0;i<this.state.messageList.length;i++) {
          this.state.messageList[i].data.parent = null;
        }
        var list = JSON.parse(JSON.stringify(this.state.messageList));
        var oldLength = list.length;
        for(var i=0;i<res.length;i++) {
          if( tabId.indexOf(res[i].event_local_id) == -1 && res[i].description.whois_sender == 'OWNER' ) {
            list.push( await this.StoryEventToMessage(res[i]) );
          }
        }
        for(var i=0;i<list.length;i++) {
          list[i].data.parent = this;
        }
        this.setState({messageList:list,lastVersion:lastVersion},this.CheckRelou.bind(this));
      }
    }
    setTimeout(()=>{this.StorySync()},STORY_SYNC_TIMEOUT);
  }

  _onMessageWasSent(message) {
    message.author = 'me';
    message.data.user = 'Finder';
    message.data.ts = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    message.data.id = moment().format('YYYYDDMMHHmmssSSS');
    var obj = { story_id:this.state.story_id,
                contact_id:this.state.contact_id,
                text:message.data.text,
                id:message.data.id,
              };
    this.backend.storySend(obj,(res)=>{
      if( StrDef(res.error) ) {
        window.notify(i18n.t('all.error'),'danger');
        var tab = JSON.parse(JSON.stringify(this.state.messageList));
        for(var i=0;i<tab.length;i++) {
          if( tab[i].data.id == obj.id ) {
            tab.splice(i,1);
            break;
          }
        }
        this.setState({messageList:tab},this.CheckRelou.bind(this));
      }
      else {

      }
    });

    this.setState({messageList: [...this.state.messageList, message]},this.CheckRelou.bind(this));
  }

  _onShareGeolocate(position) {
    // Pour la mise à jour
    var message = {}
    message.author = 'me'
    message.type = 'text'
    message.data = {}
    message.data.geo = true
    message.data.user = 'Finder';
    message.data.ts = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    message.data.id = moment().format('YYYYDDMMHHmmssSSS')
    message.data.parent = this
    message.data.onclick = {}
    message.data.onclick.latitude = position.coords.latitude
    message.data.onclick.longitude = position.coords.longitude

    // Pour l'envoie à l'API
    var obj = {
      id: moment().format('YYYYDDMMHHmmssSSS'),
      story_id: this.state.story_id,
      contact_id: this.state.contact_id,
      geo: true,
      position: {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }
    }

    // Envoie à l'API
    this.backend.storySend(obj, res => {
      if ( StrDef(res.error) ) {
        window.notify(i18n.t('all.error'),'danger');
        var tab = JSON.parse(JSON.stringify(this.state.messageList));
        for(var i=0;i<tab.length;i++) {
          if( tab[i].data.id == obj.id ) {
            tab.splice(i,1);
            break;
          }
        }
        this.setState({messageList:tab},this.CheckRelou.bind(this));
      }
    })

    // Mettre à jour la vie
    this.setState({messageList: [...this.state.messageList, message]},this.CheckRelou.bind(this));
  }

  CheckRelou() {
    var list = this.state.messageList;
    var other = false;
    if( list.length > 0 && list.length >= 5 ) {
      for (var i = list.length - 1; i > list.length - 6; i--) {
        console.log(list[i])
        if( list[i].author == 'other' ) {
          other = true;
          break;
        }
      }
      if( !other ) {
        this.setState({waitingForResponse:true});
      }
      else {
        this.setState({waitingForResponse:false});
      }
    }
    else {
      this.setState({waitingForResponse:false});
    }
  }

  onGeoClick(geo) {
    switch( getOS() ) {
      case 'iOS': //window.open('http://maps.apple.com/?saddr=46.6714322,-1.4180796&daddr=46.6714322,-1.4180796&dirflg=w'); break;
      case 'Mac':
      case 'Android':
      case 'Windows':
      case 'Linux': window.open('https://www.google.com/maps/search/?api=1&query='+geo.latitude+','+geo.longitude); break;
    }
  }

  onPhotoClick(img) {
    console.log( img );
    this.setState({photo:img},()=>{
      this.ModalPhoto();
    });

  }

  ModalPhoto() {
    this.setState({modalPhoto:!this.state.modalPhoto});
  }

  FullPhotoLoad() {
    var ratio = this.refs.fPhoto.naturalWidth/this.refs.fPhoto.naturalHeight
    var width = this.refs.fPhoto.height*ratio;
    this.setState({modalWidth:width});
  }

  render() {
    let {
      loginEmailState,
      loginPasswordState,
      forgotPassCodeState,
    } = this.state;

    var marginLeft = undefined;
    var tmp = isMobile.any?Math.max((window.innerWidth-this.state.modalWidth)/2,0):0;
    if( tmp > 0 ) marginLeft = tmp+'px';

    console.log('isMobile.any = ', isMobile.any );

    return (
      <div>

        <NotificationAlert ref="notificationAlert"/>

        <div style={{display:'flex',justifyContent:'center',alignItems:'center',minWidth:'100vw',minHeight:'100vh'}}>

          { this.state.authentified &&
            <>
              <Launcher
                agentProfile={{
                  teamName: 'J\'ai trouvé un objet',
                }}
                onMessageWasSent={this._onMessageWasSent.bind(this)}
                onShareGeolocate={this._onShareGeolocate.bind(this)}
                messageList={this.state.messageList}
                isOpen
                placeholder={'écrire ici...'}
                waitingForResponse={this.state.waitingForResponse}
                waitingText={i18n.t('chat.waitingText')}
              />
            </>
          }

          { !this.state.authentified &&
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">L'accès est refusé</CardTitle>
                  </CardHeader>
                  <CardBody style={{ textAlign: 'center' }}>
                    <Link to={'/'} style={{ fontSize: 24, fontWeight: 600 }}>Retour</Link>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }

          { isMobile.any && this.state.modalPhoto &&
            <div style={{position:'fixed',top:0,left:0,width:window.innerWidth,height:window.innerHeight,zIndex:999999,background:'white'}}>
              <img ref='fPhoto' src={this.state.photo}
                  style={{borderRadius:0,objectFit:'contain',width:'100%',height:(window.innerHeight-44)+'px'}}/>
              <br/>
              <Button color="neutral" style={{margin:0,width:'100%',height:44}} onClick={this.ModalPhoto}>{i18n.t('chat.geoClose')}</Button>
            </div>
          }
        </div>

        { !isMobile.any &&
          <Modal isOpen={this.state.modalPhoto} toggle={this.ModalPhoto} backdrop={true} style={{marginLeft:marginLeft,maxWidth:this.state.modalWidth}}>
            <div style={{background:'transparent'}}>
              <img ref='fPhoto' src={this.state.photo}
                  style={{borderRadius:'0.6rem',borderBottomLeftRadius:0,borderBottomRightRadius:0,height:'calc(100vh - '+(isMobile.any?'74':'120')+'px)'}}
                  onLoad={this.FullPhotoLoad.bind(this)}/>
              <br/>
              <Button color="neutral" style={{width:'99%'}} onClick={this.ModalPhoto}>{i18n.t('chat.geoClose')}</Button>
            </div>
          </Modal>
        }
      </div>
    );
  }
}

export default Pages;
