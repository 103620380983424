import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse, Progress } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import i18n from "../../assets/lib/i18n";
import {StrDef} from '../../assets/lib/utils';


var ps;

class AllocBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entity:null,
    };

    this.state.entity = sessionStorage.getItem('entity');
    if( StrDef(this.state.entity) ) this.state.entity = JSON.parse(this.state.entity);
  }

  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    var total = (StrDef(this.state.entity)&&StrDef(this.state.entity.allocation))?this.state.entity.allocation:0;
    var used = parseInt(total*34/100);
    var percent = (used/total)*100;

    return (
      <div style={{width:'250px'}}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          <div style={{display:'flex'}}>
            <p style={{fontSize:'0.8em'}}><b>{i18n.t('navbar.agentAlloc')}</b></p>
          </div>
          <div style={{display:'flex'}}>
            <p style={{fontSize:'0.8em'}}>{used} / {total}</p>
          </div>
        </div>
        <Progress multi>
          <Progress
            bar
            barClassName="progress-bar-success"
            color='primary'
            max="100"
            value={percent}
          />
          { false &&
            <Progress
              bar
              barClassName="progress-bar-warning"
              max="100"
              value="30"
            />
          }
        </Progress>
      </div>
    );
  }
}

export default AllocBar;
