import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";

import i18n from "../../assets/lib/i18n";
import {StrDef,Phone} from '../../assets/lib/utils';
import OMap from '../../components/Map/OMap.jsx';
import {sharedAPI} from "../../assets/lib/api";


// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.MapFullScreen = this.MapFullScreen.bind(this);

    this.state = {
      mapStyle:{},
      zones:[],
      selection:[],
    };

    this.backend = sharedAPI();
    this.LoadList();

    var data = {
      name:'duval',
      firstname:'robert',
      company:'agent 008',
      address:'fdssds qsd fdfsq fd',
      zip:'67800',
      city:'grenoble',
      mail:'dlkdk@slmd.com',
      phone:'7869837578369873789',
      region:'',
      country:'France'
    };

    this.backend.entity_put(data,(r)=>{
      console.log( r );
    });
  }

  MapFullScreen() {
    var style = {};
    if( this.state.mapFullScreen ) {
      style = {
        position:'relative',
        zIndex:999999999999,
        top:undefined,
        left:undefined,
        width:'100%',
        height:undefined,
      };
      this._map.SetSize('100%','328px');
    }
    else {
      style = {
        position:'fixed',
        zIndex:9999,
        top:0,
        left:0,
        width:window.innerWidth+'px',
        height:window.innerHeight+'px',
      };
      this._map.SetSize(window.innerWidth+'px',window.innerHeight+'px');
    }
    this.setState({ mapFullScreen:!this.state.mapFullScreen,
                    mapStyle:style });
  }

  LoadList() {
    this.backend.get_entities(1,(res)=>{
      if( StrDef(res.error) ) {window.notify(i18n.t('all.error'),'danger'); return;}
      var tab = [], tabZone = [];
      // on liste tous les dept de ts les agents
      for(var i=0;i<res.length;i++) {
        if( StrDef(res[i].long_description.zone) ) {
          tab.push({name:res[i].long_description.company,zone:res[i].long_description.zone});
          tabZone = tabZone.concat(res[i].long_description.zone);
        }
      }
      this.setState({zones:tab,selection:tabZone});
    });
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    {i18n.t('dashboard.zone')}
                    <div className="icon-big text-right" style={{position:'absolute',right:'9px',top:'15px'}}>
                      <Button className="btn-icon btn-neutral" color="primary" onClick={this.MapFullScreen}>
                        <i className="nc-icon nc-globe" />
                      </Button>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div style={this.state.mapStyle}>
                    { this.state.selection.length &&
                      <OMap
                        ref={(c)=>this._map=c}
                        mapRadius={this.state.mapFullScreen?'0px':undefined}
                        selection={this.state.selection}
                        zones={this.state.zones}
                        mode={'allZone'}
                        disabled={this.gender=='agent'}
                        onChange={this.ZoneChange}/>
                    }
                  </div>
                  { this.state.mapFullScreen &&
                    <div style={{position:'fixed',zIndex:99999,top:'10px',right:'20px'}}>
                      <Button color="primary" onClick={this.MapFullScreen}>
                        {i18n.t('agent.exit')}
                      </Button>
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
