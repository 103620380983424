import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SendIcon from './icons/SendIcon';
import FileIcon from './icons/FileIcon';
import EmojiIcon from './icons/EmojiIcon';
import PopupWindow from './popups/PopupWindow';
import EmojiPicker from './emoji-picker/EmojiPicker';


class UserInput extends Component {

  constructor() {
    super();
    this.state = {
      inputActive: false,
      inputHasText: false,
      emojiPickerIsOpen: false,
      emojiFilter: ''
    };
  }

  componentDidMount() {
    this.emojiPickerButton = document.querySelector('#sc-emoji-picker-button');
  }

  handleGeolocation(event) {
    event.preventDefault()
    this.userInput.textContent = ''
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.props.onShareGeolocate(position)
        // this.userInput.textContent = `https://www.google.com/maps/search/?api=1&query=${position.coords.latitude},${position.coords.longitude}`
        // this._submitText(event)
      })
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      return this._submitText(event);
    }
  }

  handleKeyUp(event) {
    const inputHasText = event.target.innerHTML.length !== 0 &&
      event.target.innerText !== '\n';
    this.setState({ inputHasText })
  }

  _showFilePicker() {
    this._fileUploadButton.click()
  }

  toggleEmojiPicker = (e) => {
    e.preventDefault();
    if (!this.state.emojiPickerIsOpen) {
      this.setState({ emojiPickerIsOpen: true });
    }
  }

  closeEmojiPicker = (e) => {
    if (this.emojiPickerButton.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({ emojiPickerIsOpen: false });
  }

  _submitText(event) {
    event.preventDefault();
    const text = this.userInput.textContent;
    if (text && text.length > 0) {
      this.props.onSubmit({
        author: 'me',
        type: 'text',
        data: { text }
      });
      this.userInput.innerHTML = '';
    }
  }

  _onFilesSelected(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.props.onFilesSelected(event.target.files)
    }
  }

  _handleEmojiPicked = (emoji) => {
    this.setState({ emojiPickerIsOpen: false });
    if(this.state.inputHasText) {
      this.userInput.innerHTML += emoji;
    } else {
      this.props.onSubmit({
        author: 'me',
        type: 'emoji',
        data: { emoji }
      });
    }
  }

  handleEmojiFilterChange = (event) => {
    const emojiFilter = event.target.value;
    this.setState({ emojiFilter });
  }

  _renderEmojiPopup = () => (
    <PopupWindow
      isOpen={this.state.emojiPickerIsOpen}
      onClickedOutside={this.closeEmojiPicker}
      onInputChange={this.handleEmojiFilterChange}
    >
      <EmojiPicker
        onEmojiPicked={this._handleEmojiPicked}
        filter={this.state.emojiFilter}
      />
    </PopupWindow>
  )

  _renderSendOrFileIcon() {
    return (
      <div className="sc-user-input--button" style={{ marginRight: 20 }}>
        <SendIcon onClick={this._submitText.bind(this)} />
      </div>
    )
    if (this.state.inputHasText) {
      return (
        <div className="sc-user-input--button">
          <SendIcon onClick={this._submitText.bind(this)} />
        </div>
      )
    }
    return (
      <div className="sc-user-input--button">
        <FileIcon onClick={this._showFilePicker.bind(this)} />
        <input
          type="file"
          name="files[]"
          multiple
          ref={(e) => { this._fileUploadButton = e; }}
          onChange={this._onFilesSelected.bind(this)}
        />
      </div>
    )
  }

  render() {
    const { emojiPickerIsOpen, inputActive } = this.state;
    return (
      <form className={`sc-user-input ${(inputActive ? 'active' : '')}`}>
        <div className="sc-user-input--button" style={{ marginLeft: 10 }}>
          <button
            className="sc-user-input--file-icon-wrapper"
            onClick={this.handleGeolocation.bind(this)}
            >
            <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9 22.7368C8.76603 22.7368 8.53791 22.663 8.34751 22.5256C8.00551 22.2811 -0.0325256 16.4161 9.90413e-05 9.08912C9.90413e-05 4.08016 4.03768 0 9 0C13.9623 0 17.9999 4.08016 17.9999 9.09481C18.0325 16.4161 9.99449 22.2811 9.65249 22.5256C9.46209 22.663 9.23397 22.7368 9 22.7368ZM15.7499 9.10049C15.7499 5.33297 12.7226 2.2737 9 2.2737C5.27742 2.2737 2.25007 5.33297 2.25007 9.09481C2.22645 14.1436 7.18764 18.6751 9 20.1621C10.8135 18.6762 15.7736 14.1458 15.7499 9.10049ZM13.2632 9C13.2632 11.3511 11.3511 13.2632 9 13.2632C6.64887 13.2632 4.73684 11.3511 4.73684 9C4.73684 6.64887 6.64887 4.73684 9 4.73684C11.3511 4.73684 13.2632 6.64887 13.2632 9ZM11.1316 9C11.1316 7.82443 10.1756 6.86842 9 6.86842C7.82443 6.86842 6.86842 7.82443 6.86842 9C6.86842 10.1756 7.82443 11.1316 9 11.1316C10.1756 11.1316 11.1316 10.1756 11.1316 9Z" fill="black"/>
            </svg>
          </button>
        </div>
        <div style={{ padding: 5 }}>
          <div
            role="button"
            tabIndex="0"
            onFocus={() => { this.setState({ inputActive: true }); }}
            onBlur={() => { this.setState({ inputActive: false }); }}
            ref={(e) => { this.userInput = e; }}
            onKeyDown={this.handleKeyDown.bind(this)}
            onKeyUp={this.handleKeyUp.bind(this)}
            contentEditable="true"
            placeholder={this.props.placeholder}
            className="sc-user-input--text"
          >
          </div>
        </div>
        {/* <div className="sc-user-input--buttons">
          <div className="sc-user-input--button"></div>
          <div className="sc-user-input--button">
            {this.props.showEmoji && <EmojiIcon
              onClick={this.toggleEmojiPicker}
              isActive={emojiPickerIsOpen}
              tooltip={this._renderEmojiPopup()}
            />}
          </div>
        </div> */}
          {this._renderSendOrFileIcon()}
      </form>
    );
  }
}

UserInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onShareGeolocate: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool
};

export default UserInput;
